.error-block {
    &__content {
        height: 200px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -($header-height + 20));
        text-align: center;
    }
}
