.page-header {
    height: 500px;
    background-size: cover;
    background-position: center;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-top: 102px;

    @media (max-width: $responsive-small-tablet) {
    margin-top: 0;
    }

    &--light {
        color: $color-white;

        h1 {
            color: $color-white;
            @media (max-width: $responsive-small-tablet) {
                font-size: 2.2rem;
            }
        }
    }

    &--dark {
        color: $alteza-black;

        h1 {
            color: $alteza-black;
            @media (max-width: $responsive-small-tablet) {
                font-size: 2.2rem;
            }
        }
    }

    &__content {
        text-align: center;
        width: calc(#{$default-containter-width} - 17%);
        max-width: $default-containter-width;
    }
}
