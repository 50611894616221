.content-block {        
    &__content-title {
        color: $alteza-black;
    }

    // size
    &--small-width {
        max-width: $container-width-small;
    }

    // medium is default
    &--medium-width {
        max-width: $container-width-medium;
    }

    &--full-width {
        max-width: $container-width-big;
    }

    // alignment
    &--center {
        text-align: center;
    }

    &--left {
        text-align: left;
    }

    &--right {
        text-align: right;
    }

    // background color
    &--white {
        background-color: $color-white;
    }

    &.container {
        padding: 0 10px;
    }
}
