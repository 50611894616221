.vacancy-block {
    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $alteza-black;
        @media (max-width: $responsive-small-tablet) {
            padding: 0 10px;
        }
    }

    &__navigation {
        align-self: center;
        font-size: 30px;

        @media (max-width: $responsive-medium-laptop) {
            display: none;
        }
    }

    &__vacancies {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &--error,
        &--loader {
            padding-top: 50px;
            margin: 0 auto;
        }
    }

    &__item {
        position: relative;
        margin-right: 10px !important;
        margin-left: 10px !important;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        padding: 22px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-basis: 200px;
        flex-grow: 1;
        margin-bottom: 22px;
        box-shadow: $box-shadow !important;

        .btn {
            align-self: flex-end;
        }
    }

    &__item-content {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
