.intro-block {
    max-width: calc(#{$default-containter-width} - 17%);
    text-align: center;
    padding: 0 10px;

    &__content {
        font-size: 2rem;
        color: #636363;
        font-weight: 200;

        strong {
            color: $alteza-green;
            font-weight: bold;
        }
    }
}
