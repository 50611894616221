.twitter-block {
    max-width: calc(#{$container-width} - 40%);
    text-align: center;
    position: relative;
    padding: 0 10px;

    &__info {
        &-date {
            display: inline-block;
        }
    }

    span {
        width: 100%;
        display: inline-block;
    }

    &__icon {
        margin: 0 auto;

        // material ui overrides
        background-color: $alteza-green !important;
        width: 52px !important;
        height: 52px !important;

        i {
            color: $color-white;
            font-size: 32px;
        }
    }

    &__navigation {
        font-size: 30px;
        position: absolute;
        top: 50%;
        transform: translateX(-50%);

        &--next {
            right: -30px;
        }

        &--prev {
            left: -30px;
        }
    }
}
