.contact-block {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 15px;
    display: flex;
    flex-basis: 200px;
    display: flex;
    flex-wrap: wrap;
    box-shadow: $box-shadow;

    @media (max-width: $responsive-small-tablet) {
        flex-direction: column;
    }

    &__info {
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        padding: 15px;
        flex: 1;
        box-shadow: $box-shadow;
    }

    &__content {
        margin-top: 10px;
        .row {
            display: flex;
            align-items: center;

            .left {
                height: 100px;
                display: flex;
                flex-direction: column;
                flex: 1;

                span {
                    flex-grow: 1;
                    flex-shrink: 0;
                    flex: 0 0 100%;
                    width: 100%;
                    flex: 100%;
                }
            }

            .right {
                height: 100px;
                display: flex;
                flex-direction: column;
                flex: 1;

                span {
                    flex-grow: 1;
                    flex-shrink: 0;
                    flex: 0 0 100%;
                    width: 100%;
                    flex: 100%;
                }
            }
        }
    }

    &__content-text {
        padding-top: 20px;
    }
    &__map {
        width: 100%;
    }
    &__form {
        flex: 1;
        padding-left: 30px;
        padding-right: 0;
        overflow: hidden;
    
        @media (max-width: $responsive-small-tablet) {
            padding: 0;
        }
        input[type=submit] {
            margin-left: 0;
        }
        input, textarea {
            margin-top: 10px;
        }
    }
}




