.statistics-block {
    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 1rem;

        &--green {
            h2 {
                color: $color-white;
                @media (max-width: $responsive-small-tablet) {
                    font-size: 1.4rem;
                }   
            }
            background-color: $alteza-green;
        }

        &--default {
            h2 {
                color: $alteza-black;
                @media (max-width: $responsive-small-tablet) {
                    font-size: 1.4rem;
                }   
            }
            background-color: $color-white;
        }
    }
}

