.loader-container {
    &.full {
        height: calc(100vh - #{$footer-height + $header-height});
    }

    &.small {
        .loader-content {
            position: relative;
            margin: 0 auto;
            margin-top: 100px;
            top: initial;
            left: initial;
            transform: initial;
            width: 200px;
        }
    }
}

.loader-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
}

.loader {
    width: 80px;
    height: 80px;

    @keyframes showText {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
      }

    span {
        width: 200px;
        display: block;
        text-align: center;
        opacity: 0;
        animation: 1s ease-out 0s 1 showText;
        animation-fill-mode: forwards;
    }

    &:before {
        content: " ";
        position: absolute;
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        margin-left: 68px;
        margin-top: -100px;
        border-radius: 50%;
        border: 6px solid $alteza-green;
        border-color: $alteza-green transparent $alteza-green transparent;
        animation: loader 1.2s linear infinite;
    }
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
