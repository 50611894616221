.vacancies-page {
    .vacancies {
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
    }

    .vacancies-empty {
        padding-top: 50px;
        margin: 0 auto;
    }

    .vacancies-item {
        position: relative;
        margin-right: 10px !important;
        margin-left: 10px !important;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        padding: 22px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 22px;
        flex-grow: 1;
        flex-basis: 312px;
    }

    .vacancies-item-content {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .vacancies-actions {
        align-self: flex-end;
    }

    &__categories {
        position: relative;
        display: inline-flex;
        vertical-align: middle;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 2em;
    }

    &__categories-button {
        margin: 0;
        border-radius: 0;
        border: 1px solid $alteza-green !important;
        border-left: 0 !important;
        border-right: 1px solid $alteza-green !important;
        background-color: #fcfbfc !important;

        &:first-child {
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            border-left: 1px solid $alteza-green !important;
        }

        &:last-child {
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
            border-right: 1px solid $alteza-green !important;
        }

        &.active {
            color: #212529;
            background-color: $alteza-green !important;
            border-color: $alteza-green !important;
        }
    }

    &--single {
        width: 100%;
        display: flex;
        min-height: 100vh;
        background-color: $color-white;
        z-index: 99;
        margin-top: -350px;
        position: relative;
        flex-flow: column wrap;

        box-shadow: $box-shadow !important;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
    }
    &--single-content {
        padding: 22px;
    }
}
