.footer {
    height: $footer-height;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: $responsive-small-tablet) {
        margin-bottom: $header-height_mobile;
    }

    &__copyright {
        display: inline-block;
        color: $color-white;
        text-align: center;

        button {
            color: $color-white;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__copyright-slogan {
        display: block;
        margin: 0 auto;
        padding-top: 10px;
    }

    &__menu {
        display: inline-block !important;
        padding-inline-start: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
    &__menu-item {
        list-style: none;
        display: inline-block;
        padding-left: 0 !important;
    }

    &__social {
        display: flex;
        justify-content: center;
    }
    &__social-icon {
        display: block;
        margin: 0 8px 0 8px;
        cursor: pointer;

        // override material-ui props
        width: 52px !important;
        height: 52px !important;
        background-color: $color-white !important;
        font-size: 32px !important;

        i {
            color: $alteza-green;
        }

        &:hover {
            opacity: 0.5;
        }
    }
}
