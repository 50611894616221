.quote-block {
    height: 500px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3em;
    padding: 0 10px;

    background-size: cover;
    background-position: center;

    &--light {
        .quote-block__content {
            color: $color-white;
        }
        .quote-block__content-quote {
            color: $color-white;
        }
    }

    &--dark {
        .quote-block__content {
            color: $alteza-black;
        }
        .quote-block__content-quote {
            color: $alteza-black;
        }
    }

    &__content {
        text-align: center;
        width: calc(#{$default-containter-width} - 17%);
        max-width: $default-containter-width;
    }

    &__content-quote {
        &:before,
        &:after {
            content: '"';
        }
    }

    &__content-author {
        &:before {
            content: "- ";
        }
    }
}
