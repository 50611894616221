.posts-block {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;

    .posts {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .posts-item {
        position: relative;
        margin-right: 10px !important;
        margin-left: 10px !important;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        padding: 22px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        margin-bottom: 22px;
        background-color: #fff;

        -webkit-box-shadow: 0 0 65px -14px rgba(173, 170, 173, 0.33) !important;
        -moz-box-shadow: 0 0 65px -14px rgba(173, 170, 173, 0.33) !important;
        box-shadow: 0 0 65px -14px rgba(173, 170, 173, 0.33) !important;
        overflow: hidden;


        .image_left {
            .posts-item-content {
                order: 2;
                padding-left: 1em;
                box-sizing: border-box;
                padding-top: 10px;
            }
            .posts-item-image {
                @media (min-width: $responsive-small-tablet) {
                    order: 1;
                }
            }
        }
        .image_right {
            .posts-item-content {
                order: 1;
                padding-right: 1em;
                box-sizing: border-box;
            }
            .posts-item-image {
                @media (min-width: $responsive-small-tablet) {
                    order: 2;
                }
            }
        }

        &--hasImage {
            @media (min-width: $responsive-small-tablet) {
                flex-basis: 53.5% !important;

                .posts-item-content {
                    max-width: 60% !important;
                }

                .posts-item-image {
                    max-width: 40% !important;
                }

                +.posts-item--hasImage {
                    &:nth-child(2n) {
                        flex-basis: 20% !important;
                        .posts-item-content {
                            max-width: 100% !important;
                            padding-left: 0 !important;
                        }
                        .posts-item-image {
                            display: none !important;
                        }
                    }
                }
            }
        }

        @media (min-width: $responsive-small-tablet) {
            flex-basis: 20%;
        }
    }

    .posts-item-row {
        display: flex;
        flex-wrap: wrap;
    }

    .posts-item-image {
        @media (min-width: $responsive-small-tablet) {
            // max-width: 50%;
            height: 100%;
            min-height: 300px;
        }

        min-height: 200px;
        max-width: 200px;
        width: 100%;
        margin: 0 auto;

        order: 3;

        // &.left {
        //     order: 1;
        // }

        width: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .posts-item-content {
        order: 2;
        &__title {
            color: $alteza-green;
            padding: 0;
            text-align: left;
        }
    }

    .posts-item-actions {
        display: block;
        bottom: 0;
        align-self: flex-end;
    }

    &--loading {
        width: 100%;
        text-align: center;
    }

    &--single {
        width: 100%;
        display: flex;
        // min-height: 100vh;
        background-color: $color-white;
        z-index: 99;
        margin-top: -550px;
        position: relative;
        flex-flow: column wrap;
        box-shadow: $box-shadow !important;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        @media (min-width: $responsive-small-tablet) {
            margin-top: -380px;
        }
    }
    &--single-image {
        width: 100%;
        float: right;
        max-width: 500px;
        padding-left: 22px;
        padding-bottom: 22px;
    }
    &--single-content {
        padding: 22px;
    }
}
