@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300,400,500,600,700,800,900");

// Font
$font-size: 18px;
$font-family: "Lato", Helvetica, Arial, sans-serif;

// Colors
$alteza-green: #9dd40d;
$alteza-green_dark: rgb(121, 164, 10);
$alteza-black: rgb(34, 34, 34);
$alteza-gray: rgb(74, 74, 74);
$alteza-background: #fdfdfd;

$color-white: #fff;

$body-bg: $color-white;

$box-shadow: 0 0 65px -14px rgba(173, 170, 173, 0.33);

// Layout
$default-containter-width: 1140px;

// Buttons
$btn-box-shadow: inset 0 1px 0 rgba($color-white, 0.15), 0 1px 1px rgba($alteza-black, 0.075) !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($alteza-black, 0.125) !default;
$btn-focus-width: 0.2rem !default;

// Breakpoints
$responsive-small-tablet: 768px;
$responsive-medium-laptop: 929px;
$responsive-large-desktop: 1200px;
$responsive-xlarge-desktop: 1440px;

// sizes
$footer-height: 260px;
$header-height: 102px;
$header-height_mobile: 95px;
$container-width: 1140px;

$container-width-small: calc(#{$container-width} - calc(#{$container-width} / 100 * 50));
$container-width-medium: calc(#{$container-width} - calc(#{$container-width} / 100 * 17));
$container-width-big: $container-width;
