.header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    box-shadow: 0 0 10px $alteza-black;
    background-color: $color-white;
    height: $header-height;

    &__logo {
        display: block;
    }
}

.menu {
    max-width: 960px;
    height: 100%;
    display: table;
    table-layout: fixed;
    float: none;
    margin: 0 auto;
    display: flex;
    flex-basis: auto;
    flex-grow: 1;
    align-items: center;
    padding: 0 5px;

    &__mobile {
        ul {
            display: block !important;
            padding-inline-start: 0;
        }

        .menu-item {
            display: block;

            &:hover {
                .menu-item__sub {
                    display: none !important;
                }
            }

            &__link {
                &.open {
                    & + .menu-item__sub {
                        display: block !important;
                    }
                }
            }

            &__sub {
                position: relative;
                float: initial;
                top: 0;
                text-align: center;
                width: 100%;
                padding: 0.5rem 0;
                margin: 10px 0;
            }
            &__sub-link {
                padding: 0;
            }
        }
        .menu-item-sub {
            &::after {
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 0.255em;
                vertical-align: 0.255em;
                content: "";
                border-top: 0.3em solid;
                border-right: 0.3em solid transparent;
                border-bottom: 0;
                border-left: 0.3em solid transparent;
            }

            &.open {
                &::after {
                    display: inline-block;
                    width: 0;
                    height: 0;
                    margin-left: 0.255em;
                    vertical-align: 0.255em;
                    content: "";
                    border-bottom: 0.3em solid;
                    border-right: 0.3em solid transparent;
                    border-top: 0;
                    border-left: 0.3em solid transparent;
                }
            }
        }
    }

    .menu-item {
        &__link-sub {
            &::after {
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 0.255em;
                vertical-align: 0.255em;
                content: "";
                border-top: 0.3em solid;
                border-right: 0.3em solid transparent;
                border-bottom: 0;
                border-left: 0.3em solid transparent;
            }
        }
    }

    ul {
        flex-direction: row;
        flex: 1 1;
        margin-right: auto;
        display: flex;
        list-style: none;
        justify-content: space-between;
    }
}

.menu-item {
    flex: 0 1;
    text-transform: uppercase !important;
    text-align: center;
    padding: 10px;
    position: relative;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    @media (max-width: $responsive-medium-laptop) {
        padding: 0;

        &__link {
            font-size: 80%;
        }
    }

    &__link {
        text-decoration: none;
        cursor: pointer;
        display: block;
        white-space: nowrap;
        color: $alteza-black;
        font-weight: 400;
        text-transform: uppercase;
        width: 100%;

        &:hover,
        &:focus {
            color: rgba(0, 0, 0, 0.7); // Todo: check this color
        }
    }

    &.active {
        .menu-item__link {
            color: $alteza-green_dark;
        }
    }

    &:hover {
        .menu-item__sub {
            display: block;
        }
    }

    &__sub {
        display: none;
        position: absolute;
        background-color: $color-white;
        text-align: left;
        border: 1px solid $alteza-black;
        margin-top: 100px;
        z-index: 1000;
        float: left;
        min-width: 10rem;
        padding: 0.5rem 0;
        margin: 0.125rem 0 0;
        font-size: 1rem;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        top: 40px;
    }

    @media (max-width: $responsive-medium-laptop) {
        &__sub {
            top: 15px;
        }
    }

    &__sub-link {
        color: $alteza-black;
        width: 100%;
        padding: 0.25rem 1.5rem;
        clear: both;
        font-weight: 400;
        text-align: inherit;
        white-space: nowrap;
        border: 0;
        display: block;
        text-transform: uppercase !important;
        margin-bottom: 10px;

        @media (max-width: $responsive-medium-laptop) {
            font-size: 80%;
        }
        &:hover,
        &:focus {
            color: rgba(0, 0, 0, 0.7); // Todo: check this color
        }

        &.active {
            color: $alteza-green_dark;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.mobile-menu {
    position: fixed;
    display: block;
    bottom: 0;
    z-index: 100000;
    background-color: $color-white;
    width: 100%;
    box-shadow: 0 0 10px #0000007d !important;
    height: $header-height_mobile;

    &--open {
        display: none;
    }
}

.mobile-logo {
    width: 100px;

    img {
        padding-top: 10px;
        padding-left: 20px;
        padding-bottom: 0;
    }
}

.mobile-toggle {
    position: absolute;
    right: 0;
    margin: 20px;
    vertical-align: middle;
    text-align: center;
    padding: 0.25rem 0.75rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
}
