html {
    font-size: $font-size;
}

html,
body {
    font-family: $font-family;
    text-rendering: optimizeLegibility;
    text-rendering: geometricPrecision;
    font-smooth: always;
    font-smoothing: antialiased;
    margin: 0;
    background-color: $alteza-background;
    line-height: 1.5;

    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: $alteza-green;
}

h1,
.h1 {
    font-size: 2.5rem;
    margin-top: 0;
}

h2,
.h2 {
    font-size: 2rem;
    margin-top: 0;
}

h3,
.h3 {
    font-size: 1.75rem;
}

h4,
.h4 {
    font-size: 1.5rem;
}

a {
    color: $alteza-green;
    text-decoration: none;
}

.btn-primary {
    @include button-variant($alteza_green, $alteza_green);
    color: $color-white;
    border-color: transparent !important;
}

.btn-secondary {
    @include button-variant($color-white, $alteza_green);
    color: $alteza-green;
}

.btn-outline-primary {
    @include button-outline-variant($alteza-green, $alteza-black);
}

.uppercase {
    text-transform: uppercase;
}

#root {
    // min-height: 100%;
    // display: flex;
    // flex-direction: column;
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    text-decoration: none;
    margin: 0.5rem;
    cursor: pointer;
}

.content {
    @media (min-width: $responsive-small-tablet) {
        margin-top: $header-height;
    }
    background-color: #fefefe;

    min-height: calc(100vh - #{$header-height});
    // min-height: 100vh;
    // margin-bottom: -($footer-height + $header-height);
    margin-bottom: -$footer-height;

    &:after {
        content: "";
        display: block;
        height: $footer-height;
    }
}

.container {
    margin: 0 auto;
    max-width: $default-containter-width;
    margin-top: 50px;
    margin-bottom: 50px;
}

::selection {
    color: $color-white;
    background: $alteza-green;
}

.desktop {
    @media (min-width: $responsive-small-tablet) {
        display: block;
    }
    display: none;
}

.mobile {
    @media (max-width: $responsive-small-tablet) {
        display: block;
    }
    display: none;
}

*:focus {
    outline-color: $alteza-green;
}

.background {
    &--center,
    &--center-center {
        background-size: cover;
        background-position: center center !important;
    }

    &--left-center {
        background-position: left center !important;
    }

    &--right-center {
        background-position: right center !important;
    }

    &--top,
    &--top-center {
        background-size: cover;
        background-position: center top !important;
    }

    &--left-top {
        background-position: left top !important;
    }

    &--right-top {
        background-position: right top !important;
    }

    &--bottom,
    &--bottom-center {
        background-size: cover;
        background-position: center bottom !important;
    }

    &--left-bottom {
        background-position: left bottom !important;
    }

    &--right-bottom {
        background-position: right bottom !important;
    }
}
@media (max-width: $responsive-small-tablet) {
    .background-mobile {
        &--center,
        &--center-center {
            background-size: cover;
            background-position: center center;
        }

        &--left-center {
            background-position: 25% center !important;
        }

        &--right-center {
            background-position: 75% center !important;
        }

        &--top {
            background-size: cover;
            background-position: center top;
        }

        &--left-top {
            background-position: 25% top !important;
        }

        &--right-top {
            background-position: 75% top !important;
        }

        &--bottom {
            background-size: cover;
            background-position: center bottom;
        }

        &--left-bottom {
            background-position: 25% bottom !important;
        }

        &--right-bottom {
            background-position: 75% bottom !important;
        }
    }
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
    display: block;
    width: 90%;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: $color-white;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    + .input-error {
        font-size: 80%;
        font-weight: 400;
    }
}

textarea {
    height: 100px;
}

input[type="submit"] {
    @extend .btn;
    @extend .btn-primary;
}

button {
    display: inline-block;
    border: none;
    margin: 0;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    font: 400 18px $font-family;

    &:focus {
        outline: 0;
    }
    &.btn-link {
        padding: 0 !important;
        color: $alteza-green;
        text-align: left;
    }
}
