// .content-image-block {
//     &.content-image-block__data {
//         display: flex;
//         flex-wrap: wrap;
//         border: 1px solid rgba(0, 0, 0, 0.125);
//         border-radius: 0.25rem;
//         box-shadow: $box-shadow !important;

//         // size
//         &--small-width {
//             max-width: $container-width-small;
//         }

//         // medium is default
//         &--medium-width {
//             max-width: $container-width-medium;
//         }

//         &--full-width {
//             max-width: $container-width-big;
//         }
//     }

//     // alignment
//     &--left {
//         .content-image-block__image {
//             order: 0;
//         }
//     }

//     &--background {
//         position: relative;
//         text-align: center;
//         border: 0;

//         .content-image-block__column {
//             flex-basis: initial;
//             min-height: 500px;
//             // position: relative;
//         }

//         .content-image-block {
//             &__image {
//                 width: 100%;
//             }

//             &__content {
//                 display: block;
//                 position: absolute;
//                 max-width: $container-width;
//                 width: 40%;
//                 left: 50%;
//                 top: 50%;
//                 transform: translate(-50%, -50%);
//                 background-color: $color-white;
//                 box-shadow: $box-shadow !important;
//                 border: 1px solid rgba(0, 0, 0, 0.125) !important;
//                 border-radius: 0.25rem !important;

//                 @media (max-width: $responsive-large-desktop) {
//                     width: 80%;
//                 }
//             }
//             &__content-text,
//             h2 {
//                 text-align: left;
//             }
//         }
//     }

//     &__column {
//         display: flex;
//         flex-direction: column;
//         flex-basis: 200px;
//         flex-grow: 1;
//         order: 1;
//     }

//     &__content {
//         padding: 1.25rem;
//         order: 1;

//         > .btn {
//             margin-left: 0;
//         }
//     }

//     &__image {
//         flex-grow: 1;
//         min-height: 200px;
//         background-size: cover;
//         background-position: top center;
//         order: 2;
//     }
// }

.content_image-block {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    box-shadow: $box-shadow !important;

    // size
    &--small-width {
        max-width: $container-width-small;
    }

    // medium is default
    &--medium-width {
        max-width: $container-width-medium;
    }

    &--full-width {
        // max-width: initial;
        max-width: $container-width-big;
    }
    // }

    // alignment
    &--left {
        .content_image-block__image {
            order: 0;
        }
    }

    &--background {
        position: relative;
        text-align: center;
        max-width: 100%;
        overflow: hidden;

        &.content_image-block--small-width {
            .content_image-block__content {
                max-width: $container-width-small;
            }
        }

        // medium is default
        &.content_image-block--medium-width {
            .content_image-block__content {
                max-width: $container-width-medium;
            }
        }

        &.content_image-block--full-width {
            .content_image-block__content {
                max-width: $container-width-big;
            }
        }

        .content_image-block-column {
            flex-basis: initial;
            min-height: 500px;
        }

        .content_image-block__image {
            width: 100%;
        }

        .content_image-block__content {
            display: block;
            position: absolute;
            max-width: $container-width;
            left: 50%;
            top: 50%;
            width: 100%;
            transform: translate(-50%, -50%);
            background-color: $color-white;
            box-shadow: $box-shadow !important;
            border: 1px solid rgba(0, 0, 0, 0.125) !important;
            border-radius: 0.25rem !important;

            &-text,
            h2 {
                text-align: left;
            }

            @media (max-width: $responsive-large-desktop) {
                width: 80%;
            }
        }
    }

    &-column {
        display: flex;
        flex-direction: column;
        flex-basis: 200px;
        flex-grow: 1;
        order: 1;
    }

    &__content {
        padding: 1.25rem;
        order: 1;

        > .btn {
            margin-left: 0;
        }
    }

    &__image {
        flex-grow: 1;
        min-height: 200px !important;
        background-size: cover;
        background-position: top center;
        order: 2;
    }
}
