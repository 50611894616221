.cards-block {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 15px;

    &__content {
        padding: 15px;
    }

    &__cards {
        display: flex;
        flex-wrap: wrap;
    }

    &__cards-card {
        margin: 15px;
        flex: 1 0 0;
        max-width: calc(33% - 30px);

        @media (max-width: $responsive-medium-laptop) {
            max-width: calc(50% - 30px);
            flex: 0 0 calc(50% - 30px);
        }

        @media (max-width: $responsive-small-tablet) {
            max-width: 100%;
            flex: 0 0 100%;
        }

        &.card {
            padding: 22px;
            border: 1px solid rgba(0, 0, 0, 0.125);
            border-radius: 0.25rem;
            box-shadow: $box-shadow !important;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-basis: 200px;
            flex-grow: 1;
            align-content: flex-start;
            flex-flow: column nowrap;
        }

        &_image {
            height: 150px;
            display: flex;
            align-items: center;
            width: 100%;

            img {
                margin: 0 auto;
                display: block;
                max-width: 100%;
            }
        }

        &_actions {
            justify-content: center;
            display: flex;
            width: 100%;
            margin-top: auto;
        }
    }
}
